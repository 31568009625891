@font-face {
  font-family: 'Public-Sans';
  src: url('./PublicSans-Medium.ttf '),
}

.Public-Sans-It {
	font-family: 'Public-Sans' !important;
  font-style: italic !important;
}

.Public-Sans {
	font-family: 'Public-Sans' !important;
  font-weight: bold !important;
}
.Public-Sans-N{
  font-family: 'Public-Sans' !important;
  font-style: normal;
}