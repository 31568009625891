@import "./variables";

@mixin q-button($bg-color) {
    color: $q-white;
    background: $bg-color;
    border: 0 none;
}

@mixin q-button-hover-active($bg-color) {
    background: $bg-color;
    color: $q-white;
    border-color: transparent;
}


@mixin q-button-outlined($color) {
    background-color: transparent;
    color: $color;
    border: 0 none;
}

@mixin q-button-text($color) {
    background-color: transparent;
    color: $color;
    border-color: transparent;
}

@mixin q-button-text-hover-active($color) {
    background: transparent;
    border-color: transparent;
    color: $color;
}

.p-button {
  &-rounded {
    border-radius: 1rem !important;
  }
  &:disabled {
    opacity: .5 !important;
  }
}

@media screen and (max-width: 600px) {
  .p-button.p-button-lg {
      font-size: 1rem !important;
  }
}

.p-button.p-button-primary, .p-buttonset.p-button-primary > .p-button, .p-splitbutton.p-button-primary > .p-button {
  @include q-button($q-primary-light);
}
.p-button.p-button-primary:enabled:hover, .p-buttonset.p-button-primary > .p-button:enabled:hover,
.p-splitbutton.p-button-primary > .p-button:enabled:hover,
.p-button.p-button-primary.p-button-outlined:enabled:active, .p-buttonset.p-button-primary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-primary > .p-button.p-button-outlined:enabled:active,
.p-button.p-button-primary:enabled:active, .p-buttonset.p-button-primary > .p-button:enabled:active,
  .p-splitbutton.p-button-primary > .p-button:enabled:active,
  .p-button.p-button-primary.p-button-outlined, .p-buttonset.p-button-primary > .p-button.p-button-outlined,
  .p-splitbutton.p-button-primary > .p-button.p-button-outlined,
  .p-button.p-button-primary:enabled:focus, .p-buttonset.p-button-primary > .p-button:enabled:focus, 
  .p-splitbutton.p-button-primary > .p-button:enabled:focus {
  @include q-button-hover-active($q-primary-dark);
}
  
.p-button.p-button-primary.p-button-text, .p-buttonset.p-button-primary > .p-button.p-button-text, .p-splitbutton.p-button-primary > .p-button.p-button-text {
  @include q-button-text($q-primary-light);
}
.p-button.p-button-primary.p-button-text:enabled:hover, .p-buttonset.p-button-primary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-primary > .p-button.p-button-text:enabled:hover,
.p-button.p-button-primary.p-button-text:enabled:active, .p-buttonset.p-button-primary > .p-button.p-button-text:enabled:active, 
.p-splitbutton.p-button-primary > .p-button.p-button-text:enabled:active {
  @include q-button-text-hover-active($q-primary-dark);
}


.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  @include q-button($q-secondary-light);
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active,
  .p-splitbutton.p-button-secondary > .p-button:enabled:active,
  .p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined,
  .p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, 
  .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  @include q-button-hover-active($q-secondary-dark);
}

.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  @include q-button-text($q-secondary-light);
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, 
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  @include q-button-text-hover-active($q-secondary-dark);
}


.p-button.p-button-grey, .p-buttonset.p-button-grey > .p-button, .p-splitbutton.p-button-grey > .p-button {
  @include q-button($q-grey-light);
}
.p-button.p-button-grey:enabled:hover, .p-buttonset.p-button-grey > .p-button:enabled:hover,
.p-splitbutton.p-button-grey > .p-button:enabled:hover,
.p-button.p-button-grey.p-button-outlined:enabled:active, .p-buttonset.p-button-grey > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-grey > .p-button.p-button-outlined:enabled:active,
.p-button.p-button-grey:enabled:active, .p-buttonset.p-button-grey > .p-button:enabled:active,
  .p-splitbutton.p-button-grey > .p-button:enabled:active,
  .p-button.p-button-grey.p-button-outlined, .p-buttonset.p-button-grey > .p-button.p-button-outlined,
  .p-splitbutton.p-button-grey > .p-button.p-button-outlined,
  .p-button.p-button-grey:enabled:focus, .p-buttonset.p-button-grey > .p-button:enabled:focus, 
  .p-splitbutton.p-button-grey > .p-button:enabled:focus {
  @include q-button-hover-active($q-grey-dark);
}
  
.p-button.p-button-grey.p-button-text, .p-buttonset.p-button-grey > .p-button.p-button-text, .p-splitbutton.p-button-grey > .p-button.p-button-text {
  @include q-button-text($q-grey-light);
}
.p-button.p-button-grey.p-button-text:enabled:hover, .p-buttonset.p-button-grey > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-grey > .p-button.p-button-text:enabled:hover,
.p-button.p-button-grey.p-button-text:enabled:active, .p-buttonset.p-button-grey > .p-button.p-button-text:enabled:active, 
.p-splitbutton.p-button-grey > .p-button.p-button-text:enabled:active {
  @include q-button-text-hover-active($q-grey-dark);
}


.p-button.p-button-blue, .p-buttonset.p-button-blue > .p-button, .p-splitbutton.p-button-blue > .p-button {
  @include q-button($q-blue-light);
}
.p-button.p-button-blue:enabled:hover, .p-buttonset.p-button-blue > .p-button:enabled:hover,
.p-splitbutton.p-button-blue > .p-button:enabled:hover,
.p-button.p-button-blue.p-button-outlined:enabled:active, .p-buttonset.p-button-blue > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-blue > .p-button.p-button-outlined:enabled:active,
.p-button.p-button-blue:enabled:active, .p-buttonset.p-button-blue > .p-button:enabled:active,
  .p-splitbutton.p-button-blue > .p-button:enabled:active,
  .p-button.p-button-blue.p-button-outlined, .p-buttonset.p-button-blue > .p-button.p-button-outlined,
  .p-splitbutton.p-button-blue > .p-button.p-button-outlined,
  .p-button.p-button-blue:enabled:focus, .p-buttonset.p-button-blue > .p-button:enabled:focus, 
  .p-splitbutton.p-button-blue > .p-button:enabled:focus {
  @include q-button-hover-active($q-blue-dark);
}
  
.p-button.p-button-blue.p-button-text, .p-buttonset.p-button-blue > .p-button.p-button-text, .p-splitbutton.p-button-blue > .p-button.p-button-text {
  @include q-button-text($q-blue-light);
}
.p-button.p-button-blue.p-button-text:enabled:hover, .p-buttonset.p-button-blue > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-blue > .p-button.p-button-text:enabled:hover,
.p-button.p-button-blue.p-button-text:enabled:active, .p-buttonset.p-button-blue > .p-button.p-button-text:enabled:active, 
.p-splitbutton.p-button-blue > .p-button.p-button-text:enabled:active {
  @include q-button-text-hover-active($q-blue-dark);
}
