.btn {
    font-family: sans-serif;
    text-transform: none;
    padding: 0.23rem 20px;

    .spinner-border-sm {
        margin: 2px 5px;
        width: 1rem;
        height: 1rem;
    }
}

.btn-lg {
    font-size: 16px;
    padding: 11px 30px;
}

.btn-just-icon {
    padding: 8px !important;
}

// Apply the mixin to the buttons
.btn-zblack { @include btn-styles($q-color-zblack, $q-text-color-dark); @include btn-active-setings($q-color-zblack, $q-text-color-dark); }
.btn-yellow { @include btn-styles($q-yellow, $q-yellow-dark, #000000c7); @include btn-active-setings($q-yellow, $q-yellow-dark); }
.btn-blue { @include btn-styles($q-blue, $q-blue-dark, #fff); @include btn-active-setings($q-color-orange, $q-color-orange); }
.btn-danger { @include btn-styles($q-color-deep-pink, $q-color-orange, #fff); @include btn-active-setings($q-color-orange, $q-color-orange); }
.btn-light-grey { 
    @include btn-styles($q-light-grey, $q-color-cool-grey, $q-text-color-dark, $q-text-color-dark); 
    @include btn-active-setings($q-color-cool-grey, $q-color-cool-grey, $q-text-color-dark); 
}
.btn-purple { 
    @include btn-styles($q-md-purple, $q-dark-purple); 
    @include btn-active-setings($q-md-purple, $q-dark-purple); 
}

.btn-dark-purple { 
    @include btn-styles($q-light-purple, $q-deep-dark-purple); 
    @include btn-active-setings($q-light-purple, $q-deep-dark-purple); 
}

.btn-white { 
    @include btn-styles($q-white, $q-pink, $q-text-color-grey, $q-text-color-grey); 
    @include btn-active-setings($q-white, $q-pink, $q-text-color-grey); 
}

// Outline Buttons
.btn-outline-danger { @include btn-outline-styles($q-color-deep-pink, $q-color-orange); @include btn-active-setings($q-color-orange, $q-color-orange); }

.btn.fb-rb {
    position: fixed;
    bottom: 40px;
    right: 2px;
    z-index: 2;
}

.btn.fb-lb {
    position:fixed;
    bottom: 40px;
    left: 6px;
    z-index: 2;
}

.btn.fb-rcenter {
    position:fixed;
    bottom: calc(100vh - 50%);
    right: 2px;
    z-index: 2;
}

.btn.fb-lcenter {
    position:fixed;
    bottom: calc(100vh - 50%);
    left: 6px;
    z-index: 2;
}

.btn.fb-rt {
    position:fixed;
    top:120px;
    right: 2px;
    z-index: 2;
}

.btn.fb-lt {
    position:fixed;
    top:120px;
    left: 4px;
    z-index: 2;
}

.btn-just-icon i {
    font-size: 15px;
    padding: 2px 0px;
}
