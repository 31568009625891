@font-face {
    font-family: "Now";
    src: url("./Now-Thin.otf") format("opentype");
    font-style: normal;
    font-display: swap;
    font-weight: 100;
}

@font-face {
    font-family: "Now-Regular";
    src: url("./Now-Regular.otf") format("opentype");
    font-style: normal;
    font-display: swap;
    font-weight: 300;
}

@font-face {
    font-family: "Now-Medium";
    src: url("./Now-Medium.otf") format("opentype");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: "Now-Bold";
    src: url("./Now-Bold.otf") format("opentype");
    font-style: normal;
    font-display: swap;
    font-weight: bold;
}

@font-face {
    font-family: "Now";
    src: url("./Now-Light.otf") format("opentype");
    font-style: normal;
    font-display: swap;
    font-weight: lighter;
}


.Now {
  font-family: 'Now' !important;
}

.Now-Regular {
  font-family: 'Now-Regular' !important;
}

.Now-Medium {
  font-family: 'Now-Medium' !important;
}

.Now-Bold {
    font-family: 'Now-Bold' !important;
}
