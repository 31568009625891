
@font-face {
  font-family: 'Caveat-Bold';
  src: url(Caveat-Bold.ttf);
}

.Caveat-Bold {
	font-family: 'Caveat-Bold' !important;
}

@font-face {
  font-family: 'Caveat-SemiBold';
  src: url(Caveat-SemiBold.ttf);
}

.Caveat-SemiBold {
	font-family: 'Caveat-SemiBold' !important;
}

@font-face {
  font-family: 'Caveat-Medium';
  src: url(Caveat-Medium.ttf);
}

.Caveat-Medium {
	font-family: 'Caveat-Medium' !important;
}

@font-face {
  font-family: 'Caveat-Regular';
  src: url(Caveat-Regular.ttf);
}

.Caveat-Regular {
	font-family: 'Caveat-Regular' !important;
}
