$q-bg-color-primary: #f5e9e3;
$q-bg-color-secondary: #e5eaeb;
$q-bg-color-light-taupe: #eee2dc70;

$q-text-color-dark: #27313a;
$q-text-color-grey: #787879;
$q-text-color-white: #fff;
$q-color-orange: #f33816;
$q-color-warm-brown: #bd7c5f;
$q-color-deep-pink: #e38f81;
$q-color-light-taupe: #eee2dc;
$q-color-cool-grey: #f4f3f1;
$q-color-qatch-blue: #688ec7;
$danger-color: $q-color-deep-pink;
$q-color-zblack: #151f20;
$q-color-blue: #51bcda;


$q-yellow: #FFD51F;
$q-yellow-dark: #e6bd0c;
$q-pink: #FAEFED;
$q-purple: #f19aff;
$q-blue: #688ec7;
$q-blue-dark: #5292f3;
$q-green:#16c464;
$q-light-grey: #f4f3f1;
$q-light-red: #A82C59;
$q-orange: rgb(252, 82, 59);
$q-fade-blue: #F3F5F6;
$font-family-now: 'Now-Regular';
$font-family-futura-bold : 'Futura-Bold';


// New Colors
$q-primary-light: #ED7F76;
$q-primary-dark: #d8655a;
$q-secondary-light: #5C8FCA;
$q-secondary-dark: #3d75b4;
$q-blue-light: #017AFF;
$q-blue-dark: #066bd8;
$q-grey-light: #80807F;
$q-grey-dark: #4e4e4e;
$q-white: #ffffff;
$q-dark-pink: #da907c;
$q-light-purple: #e3c9f3;
$q-md-purple: #ce95f1;
$q-dark-purple: #8862a2;
$q-deep-dark-purple: #72339e;