.modal-header {
    border-bottom: 1px solid $medium-gray;
    padding: 20px;
    text-align: center;
    display: block !important;

    &.no-border-header{
        border-bottom: 0 none !important;
        & .modal-title{
            margin-top: 20px;

        }
    }
    .modal-title{
        color: $black-color;
    }
    button.close{
        margin-top: -45px;
    }
}
.modal-content {
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
    .modal-header{
        h6{
            margin-top: 10px;
        }
    }
}
.quick-popup {
    .modal-content {
        border-radius: 25px;

        .modal-content {
            border: 0;
            overflow: hidden !important;

            .modal-body {
                max-height: 75vh;
                background: #f5efff;

                .home-heading {
                    font-family: "Obviously-Wide";
                    max-width: 16em;
                    font-size: 1.5rem;

                    @media screen and (max-width: 767px) {
                        font-size: 1.4rem !important;
                        margin-bottom: 5px !important;
                    }
                }

                q-button button {
                    background-color: #ce95f1;
                    color: black !important;
                    border-radius: 2rem !important;
                    border: 2px solid black !important;
                }

                button.close {
                    position: absolute !important;
                    top: 0px;
                    right: 25px;

                    &:focus-visible {
                        outline: none !important;
                    }
                }
            }
        }
    }
}

.modal-body{
    padding: 20px 50px;
    color: #000;
}
.modal-footer {
    border-top: 1px solid $medium-gray;
    padding: 0px;

    &.no-border-footer{
        border-top: 0 none;
    }
}
.modal-footer .left-side, .modal-footer .right-side{
    display: inline-block;
    text-align: center;
    width: 49%;
}
.modal-footer .btn-link{
    padding: 20px;
    width: 100%
}
.modal-footer .divider{
    background-color: $medium-gray;
    display: inline-block;
    float: inherit;
    height: 63px;
    margin: 0px -3px;
    // position: absolute;
    width: 1px;
}
.modal.fade .modal-dialog {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;

    &:has(.toast-content) {
        max-width: 28rem;
        .modal-content .modal-body {
            padding: 12px 30px;
        }
    }
}
.modal.in .modal-dialog {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
}
.modal-backdrop.in {
    opacity: 0.25;
}
.modal-register .modal-footer{
    text-align: center;
    margin-bottom: 25px;
    padding: 20px 0 15px;
    span{
        width: 100%;
    }
}
.modal-header:after {
     display: table;
     content: " ";
}
.modal-header:before{
    display: table;
    content: " ";
}
