
@font-face {
  font-family: 'Oxygen-Bold';
  src: url(Oxygen-Bold.ttf);
}

.Oxygen-Bold {
	font-family: 'Oxygen-Bold' !important;
}

@font-face {
  font-family: 'Oxygen-Light';
  src: url(Oxygen-Light.ttf);
}

.Oxygen-Light {
	font-family: 'Oxygen-Light' !important;
}

@font-face {
  font-family: 'Oxygen-Regular';
  src: url(Oxygen-Regular.ttf);
}

.Oxygen-Regular {
	font-family: 'Oxygen-Regular' !important;
}
