
/* Obviously-Wide_Semibold */
@font-face {
  font-family: "Obviously-Wide";
  font-style: normal;
  font-weight: 400;
  src: local("Wide_Semibold"), local("Wide_Semibold"), url("./Obviously-Wide_Semibold.woff2") format("woff2"), url("./Obviously-Wide_Semibold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.Obviously-Wide-It {
	font-family: 'Obviously-Wide' !important;
  font-style: italic !important;
}

.Obviously-Wide {
	font-family: 'Obviously-Wide' !important;
  font-weight: bold !important;
}
.Obviously-Wide-N{
  font-family: 'Obviously-Wide' !important;
  font-style: normal;
}