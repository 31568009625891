@import 'src/assets/sass/paper-kit/variables';
@import 'src/assets/sass/qatch-kit/variables';
@import "mixins";

/* You can add global styles to this file, and also import other style files */

.mobile-device {
  .q-navbar-container {
    table-layout: fixed;

    .side-bar-backdrop {
      width: 25% !important;
      top: 0;
      background: #1f1e1eed;
      position: fixed;
      bottom: 0;
      right: 0;

      button {
        padding: 35px;
      }
    }

    .q-side-menu {
      width: 75% !important;
      padding: 0 0 0 15px !important;
      left: 0 !important;
      right: unset !important;

      .q-menu-item-list {
        margin: 0 !important;
        overflow: hidden;
        overflow-y: auto;

        li {
          border-bottom: 1px solid #d3d3d37a;

          div {
            a.dropdown-toggle {
              color: $dark-gray;
            }
          }
        }
      }
    }
  }

  .q-page-header {
    margin-top: 0rem !important;
    padding-left: 15px;
    padding-right: 15px;

    &-title {
      font-size: 1em !important;
      font-weight: bold;
      margin: 20px 0px 20px 0px;
      line-height: 1em;
    }

    &-sub-title {
      font-size: 1.2em !important;
      margin: 0px;
    }
  }

  .q-page-filter-header {
    margin-top: 1rem !important;
    padding: .5em !important;
  }

  .q-page-filter-container {
    form>div {
      margin-top: 0 !important;

      .filter-item {
        margin-top: 1rem !important;
      }
    }
  }

  .q-page-container {
    margin-top: 1rem !important;

    .q-card-list {
      .q-card {
        padding: .8rem !important;
      }
    }

    .q-long-title {
      font-size: 3em;
    }

    .no-record-message {
      margin-top: 80px;
      margin-left: -15px;

      h3 {
        font-size: 16px;
        padding: 15px;
      }
    }
  }

  .qatch-home-container {
    background-position: 74%;

    .banner-overlay {
      .card-title {
        font-size: 3em;
      }

      .card-description {
        font-size: 1.3em;
      }
    }
  }

  .footer nav>ul>li {
    margin: 0 !important;
  }

  // modal css

  .convo-form-modal {
    .modal-content {
      .page-header {
        min-height: fit-content !important;

        .card-register {
          margin: 0 !important;
        }
      }
    }
  }

  .toast-content {
    .modal-body {

      .success,
      .error {
        height: 40px;
        width: 40px;
        border-width: 3px;

        i {
          font-size: 18px;
          margin-left: 0px;
        }
      }
    }
  }

  /*Spacing classes*/
  @include spacing-utilities(0.25);
  /*font classes*/
  @include font-sizes(0.6);
}

.desktop-device {
  .q-navbar-container {
    .q-side-menu {
      .q-menu-item-list {
        .menu-item-separator::after {
          content: ".";
          position: absolute;
          width: 2px;
          background: $q-color-cool-grey;
          color: $q-color-cool-grey;
          right: 0;
          height: 50%;
          top: 22%;
        }
      }
    }
  }

  .q-page-container {
    .no-record-message {
      margin-top: 80px;
      opacity: 0.7;
    }
  }

  /*Spacing classes*/
  @include spacing-utilities(0.50);
  /*font classes*/
  @include font-sizes(0.8);
}

.glow-on-btn {
  border-radius: 10px;
  color: $q-text-color-white !important;
  margin-left: 15px !important;
  margin-right: 15px !important;

  &:before {
    content: '';
    background: radial-gradient($q-yellow-dark, $q-yellow);
    background: linear-gradient(45deg, $q-color-blue, $q-yellow, $q-color-orange);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 500%;
    z-index: -1;
    filter: blur(0px);
    width: 105%;
    height: 105%;
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
  }

  &.active-menu-item {
    color: $q-text-color-white !important;

    &:hover {
      color: $q-text-color-white !important;
    }
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 500% 0;
  }

  100% {
    background-position: 0 0;
  }
}

[class*="col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

button.close,
button.close>i {
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 24px;
  color: grey;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.w-fit-content {
  width: fit-content;
}

.no-record-message {
  text-align: center !important;

  h3 {
    margin: auto;
    max-width: 500px;
    color: $dark-gray;
    font-family: "Now", "Helvetica", Arial, sans-serif !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.font-12 {
  font-size: 12px;
}

body {

  bswitch {
    .bootstrap-switch.bootstrap-switch-on {
  
      .bootstrap-switch-primary,
      .bootstrap-switch-primary~.bootstrap-switch-default {
        background: $q-text-color-grey;
      }
    }
  }

  .a-link {
    text-decoration: underline;;
    cursor: pointer;
  }

  .q-page-header {
    &-title {
      font-family: "Obviously-Wide";
    }

    &-sub-title {
      font-family: 'Telegraf-regular' !important;
    }

    &-inner {
      width: max-content;
      margin: auto;
    }
  }

  .spinner-border {
    vertical-align: -.4em;
  }

  .clickable {
    cursor: pointer;
  }

  form {
    .alert.alert-danger {
      margin-bottom: 0 !important;
    }
  }

  // Modals Css

  ngb-modal-backdrop, ngb-modal-window {
    z-index: 999999 !important;
  }
  
  .app-stylists-detail {
    &>div {
      width: 90% !important;
      max-width: unset !important;
      padding: 0;

      .modal-body {
        min-height: 35vh;
        max-height: 70vh;
        overflow: auto;
      }
    }
  }

  .modal-header-seprator {
    border-bottom: 1px solid #dddddd;
  }

  .top-scroller {
    height: 18px;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    position: absolute;

    &>div {
      height: 18px;
    }
  }

  .editable {
    color: $q-green;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .currency::before {
    content: '$';
    font-family: "Oxygen-Regular";
  }

  .term_and_conditions {
    font-size: 0.875em;
  }

  .required-field::after {
    content: "*";
    position: absolute;
    margin-top: 0px;
    margin-right: -18px;
    color: #B00020;
    font-size: 14px;
  }

  // modal css
  .modal-content .modal-footer>div {
    width: calc(100% - 50px);
    margin: auto;
  }

}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aba8a8;
  border-radius: 5px;
}

// @import './pride-month.scss';


// New Home Page CSS
.home-heading {
  max-width: 15em;
  margin: auto;
  line-height: 1.2;
  font-size: 2.5rem;
}

.home-description {
  max-width: 38em;
  margin: auto;
  font-size: 20px;
  line-height: 1.2em;
  font-family: 'Telegraf-regular'
}

.home-heart-icon {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
}

@media screen and (max-width: 600px) {

  .home-heading {
    padding: 0 5px;
    font-size: 20px !important;
  }

  .home-description {
    max-width: 20em !important;
    font-size: 14px;
  }

  .home-signup-form {
    .home-heading {
      font-size: 28px !important;
    }

    .signup-submit {
      font-family: 'OBVIOUSLY-WIDE';
      text-transform: uppercase;
    }

    .term_and_conditions {
      line-height: 13px !important;
      font-size: 11px !important;
      letter-spacing: -.04em;
      font-weight: normal;
    }
  }


}


.chat-image-popup-back-drop {
  background: black !important;
}

.chat-window-setting-overlay-panel {
  .p-overlaypanel-content {
    padding: 0;

    .p-listbox .p-listbox-list {
      padding: 0;

      .p-listbox-item {
        padding: 0.5rem 1rem;
      }
    }
  }
}

emoji-mart {
    .emoji-mart-preview {
        display: none !important;
    }
    
    .emoji-mart-anchor-selected {
        color: rgb(227 143 129) !important;
        .emoji-mart-anchor-bar {
            background-color: rgb(227 143 129) !important;
        }
    }
}

.qatch-skeleton {
  background-color: #e0d1d14f;
  min-height: 25em;

  &:after {
    content: "";
    animation: qatch-skeleton-animation 1.2s infinite;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translate(-100%);
    z-index: 1;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  }

  img {
    opacity: 0;
  }
}

@media (max-width: 576px) {
  .qatch-skeleton {
    min-height: 12em;
  }
}

@keyframes qatch-skeleton-animation {
  0% {
    transform: translate(-100%)
  }

  to {
    transform: translate(100%)
  }
}

.rounded-inputs-form {

  input,
  ngb-dropdown,
  .input-group,
  .form-group-no-border,
  .noUi-target {
    border-radius: 50px !important;
  }

  input[appPhoneMask] {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.border-inputs-form {

  input,
  ngb-dropdown,
  .noUi-target {
    border-style: solid;
    border-color: $danger-color;
    border-width: .14em;
  }
}

.btn-color {
  border-color: $danger-color;
  color: $danger-color;
  opacity: 1;
  border-width: .14em;
  border-style: solid;
}

.form-check {

  .form-check-sign::before,
  .form-check-sign::after {
    border: 1px solid #ccc !important;
    background-color: $q-light-grey;
  }

  .form-check-sign::after {
    background-color: #AAA7A4;
    color: #EFE;
  }
}

body.tour-open {
  overflow: auto !important;
}

@media (max-width: 600px) {
  .tolstoy-content-container {
    .tolstoy-text-bubble-container {
      width: 180px;
      height: 280px;
    }
  }
}

@media (min-width: 600px) {
  .tolstoy-content-container {
    .tolstoy-text-bubble-container {
      width: 250px;
      height: 350px;
    }
  }
}

//primeNg table css
p-table .p-datatable {
  border: 1px solid var(--banner-surface-h);
  border-top: 0;
  border-radius: 0px 0px 5px 5px;

  .p-sortable-column.p-highlight {
    .p-sortable-column-icon {
      color: var(--primary-color) !important;
    }

    background: #0000000a;
  }

  .p-sortable-column.p-highlight:hover {
    .p-sortable-column-icon {
      color: var(--primary-color) !important;
    }

    background: #0000000a;
    color:var(--primary-color) !important;
  }

  .p-datatable-wrapper {
    border-bottom: 1px solid var(--banner-surface-h);
    height: calc(100vh - 215px);

    table {
      border-collapse: separate !important;
    }
  }

  .p-paginator {
      border: 0 !important;
      border-radius: 0px 0px 5px 5px;
  }
}

// cdk drag-drop preview css
.cdk-drag-preview {
  display: none !important;
}

// p-overlaypanel css

.p-overlaypanel {
  .p-overlaypanel-content {
      padding: 0;

      p-listbox .p-listbox-list {
          margin-bottom: 0 !important;
      }
  }
}

//p-dialog css

p-dialog .p-dialog, p-dynamicdialog .p-dialog {
  min-width: 200px;
  min-height: 150px;

  // .p-resizable-handle {
  //   z-index: 10;
  // }

  .p-dialog-header {
    padding: 8px 20px 8px 30px;
    border-bottom: 1px solid var(--banner-surface-h);
  }

  .p-dialog-content:not(:has(uikit-confirmation)), .p-dialog-custom-content {
    padding: 30px 30px 15px;
  }

  .p-dialog-content:has(uikit-confirmation) {
    padding: 0 !important;
  }

  .p-dialog-footer, .p-dialog-custom-footer {
    padding: 8px 30px;
    border-top: 1px solid var(--banner-surface-h);

    button {
      margin: 0;
    }
  }
}

// form css

.p-float-label>label {
  top: 0px;
  background: var(--surface-card); //linear-gradient(var(--surface-overlay), var(--surface-card))
  padding: 0px 5px;
  font-size: 12px;
  margin-top: -.6rem;
  margin-left: -4px;
  z-index: 10;
}

uikit-dform form {
  .p-inputtext, uikit-dropdown .p-inputwrapper {
    height: 2.75rem;
  }

  .p-inputtext{
    padding: .75rem !important;
  }
}

// dropdown overlay Css

.p-overlay, .ng-trigger-overlayAnimation {
  z-index: 1000000 !important;
}

//table filters css
.p-column-filter-overlay-menu {
  .p-column-filter-constraint {
    padding-bottom: 0;

    p-columnfilterformelement, p-dropdown .p-inputwrapper {

      input, .p-inputtext {
        height: 2.75rem;
        padding: .6rem !important;
      }

      p-inputnumber > .p-inputnumber{
        height: 2.75rem;
      }
    }
  }
  
  .p-column-filter-buttonbar > button:first-child {
    margin-right: .5rem;
  }

}