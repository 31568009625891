@font-face {
    font-family: "Telegraf-regular";
    src: url("./telegraf-regular.otf") format("opentype");
}

// @font-face {
//   font-family: "Telegraf-ultra-light";
//   src: url("./telegraf-ultra-light.otf") format("opentype");
// }

// @font-face {
//   font-family: "Telegraf-ultrabold";
//   src: url("./telegraf-ultrabold.otf") format("opentype");
// }

.Telegraf-regular {
  font-family: 'Telegraf-regular' !important;
}

