@mixin underline-text($border-color){
   margin-left: auto!important;
   margin-right: auto!important;
   border-color: $border-color!important;    
   border-bottom: 3px solid $border-color !important;
   width: 30%;
   margin-top: 5px;
   display: block;
}

@mixin box-shadow(){
   box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.155) !important;
}

@mixin chip-btn() {
   box-sizing: border-box;
   border-width: 2px;
   font-size: 14px;
   font-weight: 300;
   line-height: 1.75;
   margin-right: 3px;
   cursor: pointer;
   background-color: $q-color-cool-grey;
   border-color: $q-color-cool-grey;
   color: $q-text-color-dark;
   opacity: 1;
   filter: alpha(opacity=100);
   transition: all 150ms linear;
   text-align: center;
   min-width: 80px;
   position: relative;
}

@mixin btn-active-setings($active-background, $active-border, $color: #fff) {
   &:not(:disabled):not(.disabled):active,
   &:not(:disabled):not(.disabled).active,
   .show > &.dropdown-toggle {
     color: $color;
     background-color: $active-background;
     border-color: $active-border;
   }
}