// Remove padding for screen sizes other than Lower
@media (max-width: 992px) {
    .navbar.navbar-transparent {
        padding-top: 0px;
    }
}

// generic card width
@media (min-width: 768px) {
    .card-register {
        min-width: 768px;
    }
}