/*!
* Qatch Custom style rules
*/
@import "../fonts/Now-Family/fonts";
@import "../fonts/Blacker-Pro-Dis/fonts";
@import "../fonts/futura/fonts";
@import "../fonts/Shrikhand/_fonts.scss";
@import "../fonts/Oxygen/_fonts.scss";
@import "../fonts/Caveat/_fonts.scss";
@import "../fonts/Obviously-Wide/fonts";
@import "../fonts/Public-Sans-Medium/fonts";
@import "../fonts/muli/fonts";
@import "../fonts/telegraf/fonts";


@import "paper-kit/variables";
@import "qatch-kit/variables";
@import "paper-kit/mixins";
@import "qatch-kit/mixins";
@import "qatch-kit/typography";
@import "qatch-kit/buttons";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import "~nouislider/dist/nouislider.min.css";

// Responsive queries
@import "qatch-kit/responsive";
@import 'qatch-kit/theme.scss';

body {
    color: #66615b;
    font-size: 14px;
    font-weight: 300;
    font-family: $font-family-now;
    background-color: #fff;
    strong {
        font-family: 'Now-Bold';
    }
}

// Qatch Core Css
.app-content {
    background-color: #fff;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    //align-items: center;
    justify-content: center;
}

.bg-pink {
  background-color: $q-pink;
  min-height: 100vh;
  .underline-text-dark{
    @include underline-text($q-purple);
  }
}

.bg-grey{
    background-color: #cccccc;
}

.bg-orange{
    background-color: #f459364f;
}

.bg-purple{
    background-color: #673ab74a;
}

.app-content > * {
    width: 100% !important;
}

.app-content .non-transparent-header {
    padding-top: 80px;
}

.page-header {
    background-color: #fff;
}

.page-header {
    color: $q-text-color-grey;
}

.card-register {
    background-color: transparent;
    border-radius:0%;
    margin: 50px 0 50px;
}

.card-register h1.title {
    color: $q-text-color-dark;
}

// Remove overlay from over banner image under
.page-header .filter::after {
    width: 0%;
}

.wrapper {
    background-color: transparent;
}

.card {
    color: $q-text-color-dark;
}

.shadow {
    @include box-shadow();
}

.form-group {
    margin-bottom: 0px;
}

.form-control {
    font-weight: 400;
    @include box-shadow();
    background-color: $q-color-cool-grey;
    border: 0px solid #dddddd;
}

.box-shadow-none {
    box-shadow: none !important;
}

.form-control:focus {
    background-color: $q-color-cool-grey;
    border: 1px solid $q-color-cool-grey;
    outline: 0 !important;
}

.selected-list .c-list .c-token .c-remove svg#Capa_1 {
    fill: $q-text-color-dark;
}

.input-group.form-group-no-border .input-group-prepend .input-group-text {
    border-right: transparent !important;
    background: $q-color-cool-grey !important;
    border: 1px solid $q-color-cool-grey !important;
    // border-radius: 4px 0 0 4px !important;
    transition: all 300ms linear;
}

.input-group-append {
    z-index: 9;
    top: 10px;
    position: absolute;
    right: 10px;
}

.card form .alert {
    border-radius: 0%;
}

.underline-text-dark {
    @include underline-text($q-color-qatch-blue);
}

.underline-text-light {
    @include underline-text($q-text-color-white);
}

.input-suffix {
    line-height: 20px;
    padding: 10px 15px 10px 10px;
    font-size: 15px;
}

.app-content > app-getting-started-category {
    min-height:100vh;
    background-color: #0b1011;
}

.chips .col{
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 0px;
}

.chips .chip-btn.active {
    color: $q-color-deep-pink;
}

.label {
    text-transform: none;
    color: $q-text-color-dark;
    font-size: 14px;
    font-weight: 300;
    padding-left: 0px;
}

.chip-btn {
    padding: 0.5rem 18px;
    @include box-shadow();
    @include chip-btn();
}

.chip-btn-img {
    @include chip-btn();
    @include box-shadow();
}

.chip-btn-img img{
    width: 200px;
    height: 100px;
}

.chip-btn-img i{
    position: absolute;;
    top: 5px;
    left: 10px;
}

.top-right-img {
    position: absolute;
    top: 0;
    right: 10px;
    max-width: 500px;
    max-height: 400px;
    z-index: -1;
}

.top-left-img {
    position: absolute;
    top: 0;
    left: 10px;
    max-width: 500px;
    max-height: 400px;
    z-index: -1;
}

.bottom-right-img {
    position: absolute;
    bottom: 0;
    right: 10px;
    max-width: 500px;
    max-height: 400px;
    z-index: -1;
}

.bottom-left-img {
    position: absolute;
    bottom: 0;
    left: 10px;
    max-width: 500px;
    max-height: 400px;
    z-index: -1;
}

.bg-default {
    background-color: $q-color-cool-grey;
}

.pl-15 {
    padding-left: 15px;
}

.pl-2 {
    padding-left: 0.5rem!important;
}

.pr-15 {
    padding-right: 15px;
}

.px-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.ml-15 {
    margin-left: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.mx-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.slider.slider-danger .noUi-connect,
.slider.slider-danger.noUi-connect {
  background-color: $q-color-deep-pink;
}

.slider.slider-danger .noUi-handle {
  border-color: $q-color-deep-pink;
}

// Text Color Starts here
.txt-dark {
    color: $q-text-color-dark !important;
}

.txt-white {
    color: $q-text-color-white !important;
}

.txt-danger {
    color: $q-color-deep-pink !important;
}

.txt-qatch-blue {
    color: $q-color-qatch-blue !important;
}
// Text Color Ends here


// Spacing Utils Starts her
.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.w-95 {
    width: 95% !important;
}

.w-100px {
    width: 100px !important;
}

.w-150px {
    width: 150px !important;
}

.w-180px {
    width: 180px !important;
}

.w-200px {
    width: 200px !important;
}

.w-250px {
    width: 200px !important;
}

.w-300px {
    width: 300px !important;
}

.w-350px {
    width: 350px !important;
}
// Spacing Utils ends here


// Font Size Utils starts here
.fs-10 {
    font-size: 10px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-24 {
    font-size: 24px !important;
}
// Font Size Utils ends here

.phone-number {
    white-space: nowrap;
    font-weight: bold;
    font-size: 21px;
}

.insta-gradient {
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    color: #fff;
    opacity: 0.4;
    input {
        font-weight: bold;
    }
}

.img-circle {
    background-color: $q-color-deep-pink;
}

.btn-magnify:focus i, .btn-magnify:hover i {
    -webkit-transform: scale(1.22);
    transform: scale(1.22);
    color: $q-color-orange;
}

.owner {
    cursor: pointer;
    max-width: 500px;
}

.owner .avatar {
    padding: 15px;
    max-width: 180px;
    margin: 0px auto -35px;
    display: inline-block;
}

.social img {
    width: 50px;
    height: 50px;
}

.btn-magnify {
    cursor: pointer;
}

.device-img img {
    height: 130px !important;
    padding: 10px;
}

.label.selected {
    color: $q-color-deep-pink !important;
    font-weight: 400;
}

.required {
    color: $q-color-deep-pink !important;
    font-weight: 400;
    font-size: 22px;
}

.font-family-default {
    font-family : "Montserrat", "Helvetica", Arial, sans-serif;
}

.noUi-tooltip {
    font-family : "Montserrat", "Helvetica", Arial, sans-serif !important;
}

.show-hide-password { 
    cursor: pointer;
    line-height: 20px;
    padding: 10px 5px 10px 10px;
    font-size: 15px;
    position: absolute;
    right: 0px;
    z-index: 999;
}

form {
    .input-group.form-group-no-border {
        .input-group-prepend > .input-group-text, .input-suffix {
            border-right: 1px solid #cccccccc !important;
            width: 50px;
            height: 40px;
            border-radius: 0px;
        }
        .input-group-prepend{
            padding-right: 1px;
        }
    }
}

.spinner-border-xs {
    width: .7rem;
    height: .7rem;
    border-width: .15em;
}

@media (min-width: 320px) {
    .q-page-container form {
        min-width: 295px;
    }
}

@media (min-width: 360px) {
    .q-page-container form {
        min-width: 335px;
    }
}

@media (min-width: 400px) {
    .q-page-container form {
        min-width: 375px;
    }
}

@media (min-width: 500px) {
    .q-page-container form {
        min-width: 475px;
    }
}

@media (min-width: 600px) {
    .q-page-container form {
        min-width: 575px;
    }
}

@media (max-width: 768px) {
    .q-page-container form {
        width: 95%;
    }
}

// for medium & above
@media (min-width: 768px) {
    .q-page-container form {
        width: 700px;
    }

    .chips .chip-btn:hover {
        color: $q-color-deep-pink;
    }
}