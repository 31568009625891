/**
 * @license
 * MyFonts Webfont Build ID 4100705, 2021-06-09T15:10:55-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: BlackerProDis-MdIt by Zetafonts
 * URL: https://www.myfonts.com/fonts/zetafonts/blacker-pro/display-medium-italic/
 * 
 * Webfont: BlackerProDis-Bd by Zetafonts
 * URL: https://www.myfonts.com/fonts/zetafonts/blacker-pro/display-bold/
 * 
 * 
 * Webfonts copyright: Copyright © 2018 by Cosimo Lorenzo Pancini &amp; Francesco Canovaro. All rights reserved.
 * 
 * © 2021 MyFonts Inc
*/
  
@font-face {
  font-family: "Futura-Bold";
  src: url('./FuturaLT-Bold.woff') format('woff');
}

@font-face {
  font-family: "Futura-Book";
  src: url('./FuturaLT-Book.woff') format('woff');
}

@font-face {
  font-family: "Futura-Light";
  src: url('./FuturaLT-Light.woff') format('woff');
}

.futura-bold {
	font-family: 'Futura-Bold';
}
